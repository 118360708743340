import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Quote } from "@/components/icons";

export const BlockquoteControl = createEditorControl({
  name: "blockquote",
  icon: <Quote />,
  command: ({ editor }) =>
    editor.chain().focus().toggleBlockquote().toggleItalic().run(),
  canExecute: ({ editor }) => editor.can().setBlockquote(),
});
