import { useEditorState } from "@tiptap/react";
import { ReactNode } from "react";
import { type Editor, useEditorContext } from "swash/editor";
import { ToolbarItem } from "swash/editor/components/Toolbar";

interface EditorControlProps {
  editor: Editor;
}

interface EditorControlOptions<TProps> {
  name: string;
  exclude?: string[];
  icon?: ReactNode | ((props: TProps) => ReactNode);
  command: (props: TProps & EditorControlProps) => void;
  isActive?: (props: TProps & EditorControlProps) => boolean;
  canExecute: (props: TProps & EditorControlProps) => boolean;
}

const createEditorControl = <TProps,>({
  exclude = ["title", "chapo"],
  ...options
}: EditorControlOptions<TProps>) => {
  return (props: TProps) => {
    const { editor } = useEditorContext();
    const editorState = useEditorState({
      editor,
      selector: ({ editor }) => {
        if (!editor) return null;
        return {
          active: options.isActive
            ? options.isActive({ editor, ...props })
            : editor.isActive(options.name),
          disabled:
            !options.canExecute({ editor, ...props }) ||
            exclude.some((name) => editor.isActive(name)),
        };
      },
    });

    if (!editor) return null;

    return (
      <ToolbarItem
        active={editorState?.active ?? false}
        disabled={editorState?.disabled ?? true}
        onClick={() => options.command({ editor, ...props })}
      >
        {typeof options.icon === "function"
          ? options.icon(props)
          : options.icon}
      </ToolbarItem>
    );
  };
};

export default createEditorControl;
