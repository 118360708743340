import { AnyExtension, Extension, Mark, Node } from "@tiptap/core";
import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import FocusClasses from "@tiptap/extension-focus";
import History from "@tiptap/extension-history";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";

import { Commands } from "../extensions/commands";

type AnyExtensionOptions<T extends AnyExtension> = T extends
  | Node<infer U>
  | Mark<infer U>
  | Extension<infer U>
  ? U
  : never;

export const createKit = <
  Extensions extends { [key: string]: AnyExtension },
  ExtraOptions = object,
>(
  name: string,
  extensions: (options?: Partial<ExtraOptions>) => Extensions,
) => {
  type KitOptions = ExtraOptions & {
    [key in keyof Extensions]?:
      | boolean
      | Partial<AnyExtensionOptions<Extensions[key]>>;
  };

  return Extension.create<KitOptions>({
    name,
    addExtensions() {
      return Object.entries(extensions(this.options))
        .filter(([key]) => this.options[key] !== false)
        .map(([key, extension]) => {
          const options =
            typeof this.options[key] === "boolean"
              ? undefined
              : this.options[key];
          return extension.configure(options);
        });
    },
  });
};

export const Core = {
  // Nodes
  document: Document,
  paragraph: Paragraph,
  text: Text,
  // Functionalities
  characterCount: CharacterCount,
  commands: Commands,
  focus: FocusClasses,
  history: History,
  placeholder: Placeholder,
};
