import { invariant } from "../invariant";
import {
  DragImpact,
  DraggableDescriptor,
  DraggableDimension,
  DraggableDimensionMap,
  DraggableIdMap,
  DraggableLocation,
  DroppableDimension,
  LiftEffect,
  Viewport,
} from "../types";
import getDisplacedBy from "./get-displaced-by";
import getDisplacementGroups from "./get-displacement-groups";
import getDraggablesInsideDroppable from "./get-draggables-inside-droppable";

export const getLiftEffect = ({
  draggable,
  home,
  draggables,
  viewport,
}: {
  draggable: DraggableDimension;
  home: DroppableDimension;
  draggables: DraggableDimensionMap;
  viewport: Viewport;
}): { afterCritical: LiftEffect; impact: DragImpact } => {
  const displacedBy = getDisplacedBy(home.axis, draggable.displaceBy);

  const insideHome = getDraggablesInsideDroppable(
    home.descriptor.id,
    draggables,
  );

  // in a list that does not start at 0 the descriptor.index might be different from the index in the list
  // eg a list could be: [2,3,4]. A descriptor.index of '2' would actually be in index '0' of the list
  const rawIndex = insideHome.indexOf(draggable);
  if (rawIndex === -1) {
    throw invariant("Expected draggable to be inside home list");
  }

  const afterDragging = insideHome.slice(rawIndex + 1);
  const effected = afterDragging.reduce<DraggableIdMap>((previous, item) => {
    previous[item.descriptor.id] = true;
    return previous;
  }, {});

  const afterCritical: LiftEffect = {
    displacedBy,
    effected,
  };

  const displaced = getDisplacementGroups({
    afterDragging,
    destination: home,
    displacedBy,
    last: null,
    viewport: viewport.frame,
    // originally we do not want any animation as we want
    // everything to be fixed in the same position that
    // it started in
    forceShouldAnimate: false,
  });

  const impact: DragImpact = {
    displaced,
    displacedBy,
    destination: getHomeLocation(draggable.descriptor),
  };

  return { impact, afterCritical };
};

const getHomeLocation = (
  descriptor: DraggableDescriptor,
): DraggableLocation => ({
  index: descriptor.index,
  droppableId: descriptor.droppableId,
});
