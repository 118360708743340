import { invariant } from "../../../invariant";
import messagePreset from "../../../screen-reader-message-preset";
import type {
  Announce,
  Critical,
  DragImpact,
  DragStart,
  DragUpdate,
  DraggableId,
  DraggableLocation,
  DropResult,
  MovementMode,
  OnDragEndResponder,
  OnDragStartResponder,
  OnDragUpdateResponder,
  ResponderProvided,
  Responders,
} from "../../../types";
import getAsyncMarshal, { AsyncMarshal } from "./async-marshal";
import getExpiringAnnounce from "./expiring-announce";
import { areLocationsEqual, isCriticalEqual } from "./is-equal";

const getDragStart = (critical: Critical, mode: MovementMode): DragStart => ({
  draggableId: critical.draggable.id,
  type: critical.droppable.type,
  source: {
    droppableId: critical.droppable.id,
    index: critical.draggable.index,
  },
  mode,
});

type AnyPrimaryResponderFn =
  | OnDragStartResponder
  | OnDragUpdateResponder
  | OnDragEndResponder;
type AnyResponderData = DragStart | DragUpdate | DropResult;

const execute = (
  responder: AnyPrimaryResponderFn | undefined,
  data: AnyResponderData,
  announce: Announce,
  getDefaultMessage: (data?: any) => string,
) => {
  if (!responder) {
    announce(getDefaultMessage(data));
    return;
  }

  const willExpire = getExpiringAnnounce(announce);
  const provided: ResponderProvided = {
    announce: willExpire,
  };

  // Casting because we are not validating which data type is going into which responder
  responder(data as any, provided);

  if (!willExpire.wasCalled()) {
    announce(getDefaultMessage(data));
  }
};

type WhileDragging = {
  mode: MovementMode;
  lastCritical: Critical;
  lastLocation: DraggableLocation | null;
};

export default (getResponders: () => Responders, announce: Announce) => {
  const asyncMarshal: AsyncMarshal = getAsyncMarshal();
  let dragging: WhileDragging | null = null;

  const beforeCapture = (draggableId: DraggableId, mode: MovementMode) => {
    if (dragging) {
      throw invariant(
        "Cannot fire onBeforeCapture as a drag start has already been published",
      );
    }
    // No use of screen reader for this responder
    const fn = getResponders().onBeforeCapture;
    if (fn) fn({ draggableId, mode });
  };

  const beforeStart = (critical: Critical, mode: MovementMode) => {
    if (dragging) {
      throw invariant(
        "Cannot fire onBeforeDragStart as a drag start has already been published",
      );
    }
    // No use of screen reader for this responder
    const fn = getResponders().onBeforeDragStart;
    if (fn) fn(getDragStart(critical, mode));
  };

  const start = (critical: Critical, mode: MovementMode) => {
    if (dragging) {
      throw invariant(
        "Cannot fire onBeforeDragStart as a drag start has already been published",
      );
    }
    const data: DragStart = getDragStart(critical, mode);
    dragging = {
      mode,
      lastCritical: critical,
      lastLocation: data.source,
    };

    // we will flush this frame if we receive any responder updates
    asyncMarshal.add(() => {
      execute(
        getResponders().onDragStart,
        data,
        announce,
        messagePreset.onDragStart,
      );
    });
  };

  // Passing in the critical location again as it can change during a drag
  const update = (critical: Critical, impact: DragImpact) => {
    const location = impact.destination ?? null;

    if (!dragging) {
      throw invariant(
        "Cannot fire onDragMove when onDragStart has not been called",
      );
    }

    // Has the critical changed? Will result in a source change
    const hasCriticalChanged = !isCriticalEqual(
      critical,
      dragging.lastCritical,
    );
    if (hasCriticalChanged) {
      dragging.lastCritical = critical;
    }

    // Has the location changed? Will result in a destination change
    const hasLocationChanged = !areLocationsEqual(
      dragging.lastLocation,
      location,
    );
    if (hasLocationChanged) {
      dragging.lastLocation = location;
    }

    // Nothing has changed - no update needed
    if (!hasCriticalChanged && !hasLocationChanged) {
      return;
    }

    const data: DragUpdate = {
      ...getDragStart(critical, dragging.mode),
      destination: location,
    };

    asyncMarshal.add(() => {
      execute(
        getResponders().onDragUpdate,
        data,
        announce,
        messagePreset.onDragUpdate,
      );
    });
  };

  const flush = () => {
    if (!dragging) {
      throw invariant("Can only flush responders while dragging");
    }
    asyncMarshal.flush();
  };

  const drop = (result: DropResult) => {
    if (!dragging) {
      throw invariant(
        "Cannot fire onDragEnd when there is no matching onDragStart",
      );
    }
    dragging = null;
    // not adding to frame marshal - we want this to be done in the same render pass
    // we also want the consumers reorder logic to be in the same render pass
    execute(
      getResponders().onDragEnd,
      result,
      announce,
      messagePreset.onDragEnd,
    );
  };

  // A non user initiated cancel
  const abort = () => {
    // aborting can happen defensively
    if (!dragging) {
      return;
    }

    const result: DropResult = {
      ...getDragStart(dragging.lastCritical, dragging.mode),
      destination: null,
      reason: "CANCEL",
    };
    drop(result);
  };

  return {
    beforeCapture,
    beforeStart,
    start,
    update,
    flush,
    drop,
    abort,
  };
};
