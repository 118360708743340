import {
  Extensions,
  JSONContent,
  generateJSON,
  generateText,
} from "@tiptap/core";

import { CustomFieldValueRichTextNodeFragmentFragment as RichTextNode } from "@/gql-types";

export const convertRichTextNodeToContent = (
  nodes: RichTextNode[],
  extensions: Extensions,
): JSONContent => {
  const result: JSONContent[] = [];
  let currentList: JSONContent | null = null;

  nodes.forEach((node) => {
    let { content } = generateJSON(node.text ?? "", extensions);

    if (content.length === 1 && content[0].type === "paragraph") {
      content = content[0].content || [];
    }

    // RichTextNode type to TipTap editor nodes type mapping
    const type = (() => {
      switch (node.metadata?.type) {
        case "paragraph":
        case "heading":
        case "blockquote":
          return node.metadata.type;
        case "ordered_list_item":
          return "orderedList";
        case "unordered_list_item":
          return "bulletList";
        default:
          return "paragraph";
      }
    })();

    if (type.includes("List")) {
      // Each list item is wrapped in a list node, so we need to group them
      // together to form a cohesive list that the TipTap editor can properly interpret.
      if (currentList && currentList.type === type) {
        currentList.content!.push({
          type: "listItem",
          content: [{ type: "paragraph", content }],
        });
      } else {
        currentList = {
          type: type,
          content: [
            { type: "listItem", content: [{ type: "paragraph", content }] },
          ],
        };
        result.push(currentList);
      }
    } else {
      if (currentList) currentList = null; // Reset current list
      const attrs = type === "heading" ? { level: 2 } : undefined;
      result.push({ type, attrs, content });
    }
  });

  return { type: "doc", content: result };
};

const tagMap = {
  bold: "strong",
  italic: "em",
  underline: "u",
  strike: "s",
  subscript: "sub",
  superscript: "sup",
};

export const convertContentToRichTextNode = (
  content: JSONContent,
  extensions: Extensions,
) => {
  return (
    content.content?.flatMap((node) => {
      const text = generateText(node, extensions, {
        textSerializers: {
          text: ({ node }) => {
            let formattedText = node.text || "";
            node.marks?.forEach((mark) => {
              const tag = tagMap[mark.type.name as keyof typeof tagMap];
              if (tag) formattedText = `<${tag}>${formattedText}</${tag}>`;
            });
            return formattedText;
          },
        },
        blockSeparator: "",
      });

      // TipTap editor nodes type to RichTextNode type mapping
      const type = (() => {
        switch (node.type) {
          case "paragraph":
          case "heading":
          case "blockquote":
            return node.type;
          case "orderedList":
            return "ordered_list_item";
          case "bulletList":
            return "unordered_list_item";
          default:
            return "paragraph";
        }
      })();

      if (type.includes("list_item")) {
        // Unwrap list item
        return node.content?.map((listItemNode: any) => ({
          type: "text",
          text: generateText(listItemNode.content[0], extensions),
          metadata: { type, styleName: null },
        }));
      }

      return { type: "text", text, metadata: { type, styleName: null } };
    }) ?? []
  );
};
