import { Button as AriakitButton } from "@ariakit/react/button";
import { createContext, forwardRef, memo, useContext, useMemo } from "react";
import { VscGripper } from "react-icons/vsc";
import { Teleporter, createTeleporter } from "react-teleporter";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { createSafeContext } from "@/services/hooks/useSafeContext";

type EditorBlockCapsuleToolbarButtonProps = React.ComponentProps<"button"> & {
  icon: React.ComponentType;
};
export const EditorBlockCapsuleToolbarButton = memo(
  forwardRef<HTMLElement, EditorBlockCapsuleToolbarButtonProps>(
    function EditorBlockCapsuleToolbarButton(
      {
        icon: Icon,
        title,
        onClick,
        onMouseDown,
        onMouseUp,
        onFocus,
        onBlur,
        "aria-expanded": ariaExpanded,
        ...props
      },
      ref,
    ) {
      const readOnly = useReadOnly();
      return (
        <Tooltip ref={ref} tooltip={title} aria-expanded={ariaExpanded}>
          <AriakitButton
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              event.stopPropagation();
              if (onClick) onClick(event);
            }}
            onMouseDown={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              event.stopPropagation();
              if (onMouseDown) onMouseDown(event);
            }}
            onMouseUp={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              event.stopPropagation();
              if (onMouseUp) onMouseUp(event);
            }}
            onFocus={(event: React.FocusEvent<HTMLButtonElement, Element>) => {
              event.preventDefault();
              event.stopPropagation();
              if (onFocus) onFocus(event);
            }}
            onBlur={(event: React.FocusEvent<HTMLButtonElement>) => {
              event.preventDefault();
              event.stopPropagation();
              if (onBlur) onBlur(event);
            }}
            {...props}
            type="button"
            data-editor-block-capsule-toolbar-button=""
            aria-label={title}
            disabled={readOnly}
            className={cn(
              "flex cursor-pointer rounded-full border border-transparent bg-transparent p-1 text-base text-transparent transition",
              "group-data-[readonly]/EditorBlockCapsule:pointer-events-none group-data-[readonly]/EditorBlockCapsule:opacity-0",
              props.className,
            )}
          >
            <Icon />
          </AriakitButton>
        </Tooltip>
      );
    },
  ),
);

const stopPropagation = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};

export const muteEditorEvents = () => {
  return {
    onBeforeInput: stopPropagation,
    onBlur: stopPropagation,
    onCompositionStart: stopPropagation,
    onCopy: stopPropagation,
    onCut: stopPropagation,
    onDragOver: stopPropagation,
    onDragStart: stopPropagation,
    onFocus: stopPropagation,
    onInput: stopPropagation,
    onKeyDown: stopPropagation,
    onKeyPress: stopPropagation,
    onKeyUp: stopPropagation,
    onPaste: stopPropagation,
    onSelect: stopPropagation,
    onClick: stopPropagation,
    onMouseDown: stopPropagation,
    onMouseUp: stopPropagation,
  };
};

const TeleporterContext = createSafeContext<Teleporter>();
const useTeleporter = TeleporterContext.makeSafeHook(
  "useTeleporter",
  "EditorBlockCapsuleToolbarProvider",
);

const ReadOnlyContext = createContext<boolean>(false);
const useReadOnly = () => useContext(ReadOnlyContext);

type EditorBlockCapsuleToolbarProviderProps = {
  children?: React.ReactNode;
  readOnly?: boolean;
};
export const EditorBlockCapsuleToolbarProvider: React.FC<
  EditorBlockCapsuleToolbarProviderProps
> = ({ children, readOnly }) => {
  const teleporter = useMemo(() => createTeleporter(), []);
  return (
    <TeleporterContext.Provider value={teleporter}>
      <ReadOnlyContext.Provider value={Boolean(readOnly)}>
        {children}
      </ReadOnlyContext.Provider>
    </TeleporterContext.Provider>
  );
};

type EditorBlockCapsuleToolbarProps = {
  children: React.ReactNode;
};
export const EditorBlockCapsuleToolbar: React.FC<
  EditorBlockCapsuleToolbarProps
> = ({ children }) => {
  const teleporter = useTeleporter();
  return (
    <div
      className="absolute -top-2.5 right-0 z-[2] flex flex-row-reverse gap-1 group-data-[expanded]/EditorBlockCapsule:-right-2.5"
      {...muteEditorEvents()}
    >
      {children}
      <teleporter.Target style={{ display: "contents" }} />
    </div>
  );
};

type EditorBlockCapsuleToolbarActionsProps = {
  children: React.ReactNode;
};
export const EditorBlockCapsuleToolbarActions: React.FC<
  EditorBlockCapsuleToolbarActionsProps
> = ({ children }) => {
  const teleporter = useTeleporter();
  return <teleporter.Source>{children}</teleporter.Source>;
};

export const EditorBlockCapsuleSidebar = memo(
  forwardRef<HTMLDivElement>(function EditorBlockCapsuleSidebar(_, ref) {
    return (
      <div
        ref={ref}
        data-editor-block-capsule-sidebar=""
        className={cn(
          "flex cursor-grab flex-col justify-center px-1 text-base opacity-0 transition-all",
          "group-data-[readonly]/EditorBlockCapsule:pointer-events-none group-data-[readonly]/EditorBlockCapsule:opacity-0",
        )}
      >
        <VscGripper />
      </div>
    );
  }),
);

export const EditorBlockCapsuleHighlight: React.FC = () => (
  <div
    className={cn(
      "pointer-events-none absolute inset-0 bg-primary-on opacity-0 transition-opacity",
      "group-data-[highlighted]/EditorBlockCapsule:opacity-10",
    )}
  />
);

type EditorBlockCapsuleBodyProps = {
  children?: React.ReactNode;
  fit?: boolean;
  style?: React.CSSProperties;
  attributes?: React.ComponentProps<"div">;
};
export const EditorBlockCapsuleBody = forwardRef<
  HTMLDivElement,
  EditorBlockCapsuleBodyProps
>(function EditorBlockCapsuleBody({ children, fit, style, attributes }, ref) {
  return (
    <div
      ref={ref}
      data-editor-block-capsule-body=""
      data-fit={fit ? "" : undefined}
      style={{ position: "relative" }}
      className={cn(
        "flex-1 overflow-hidden rounded border border-transparent p-1",
        "data-[fit]:p-0",
        '[.public-DraftStyleDefault-unorderedListItem_&]:flex [.public-DraftStyleDefault-unorderedListItem_&]:before:mr-2 [.public-DraftStyleDefault-unorderedListItem_&]:before:content-["•"]',
        '[.public-DraftStyleDefault-orderedListItem_&]:flex [.public-DraftStyleDefault-orderedListItem_&]:before:mr-2 [.public-DraftStyleDefault-orderedListItem_&]:before:content-[counter(list)_"-"]',
        '[blockquote_&]:m-0 [blockquote_&]:inline-flex [blockquote_&]:italic [blockquote_&]:before:border-l-2 [blockquote_&]:before:border-dusk-border-strong [blockquote_&]:before:pl-2 [blockquote_&]:before:content-[""]',
        "group-focus-within:border-primary-on",
      )}
    >
      {style || attributes ? (
        <div style={style} {...attributes}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
});

type EditorBlockCapsuleProps = React.ComponentProps<"div"> & {
  highlighted?: boolean;
  overPosition?: "top" | "bottom";
  readOnly?: boolean;
  hovered?: boolean;
  expanded?: boolean;
  change?: "deleted" | "added";
};
export const EditorBlockCapsule = forwardRef<
  HTMLDivElement,
  EditorBlockCapsuleProps
>(function EditorBlockCapsule(
  {
    highlighted,
    overPosition,
    readOnly,
    hovered,
    expanded,
    draggable,
    children,
    change,
    ...props
  },
  ref,
) {
  return (
    <div
      ref={ref}
      data-change={change}
      data-editor-block-capsule=""
      data-controlled-hover={hovered !== undefined ? "" : undefined}
      data-hovered={hovered ? "" : undefined}
      data-over-position={overPosition}
      data-readonly={readOnly ? "" : undefined}
      data-expanded={expanded ? "" : undefined}
      data-draggable={draggable ? "" : undefined}
      data-highlighted={highlighted ? "" : undefined}
      {...props}
      className={cn(
        "group/EditorBlockCapsule",
        "pointer-events-auto relative flex",
        "data-[expanded]:static data-[expanded]:cursor-default data-[expanded]:rounded data-[expanded]:pr-0",
        "data-[draggable]:cursor-grab",
        'data-[over-position="top"]:before:content-[" "] data-[over-position="top"]:before:block data-[over-position="top"]:before:h-0.5 data-[over-position="top"]:before:bg-dusk-border-strong',
        'data-[over-position="top"]:before:absolute data-[over-position="top"]:before:-top-px data-[over-position="top"]:before:left-0 data-[over-position="top"]:before:right-0',
        'data-[over-position="bottom"]:before:content-[" "] data-[over-position="bottom"]:before:block data-[over-position="bottom"]:before:h-0.5 data-[over-position="bottom"]:before:bg-dusk-border-strong',
        'data-[over-position="bottom"]:before:absolute data-[over-position="bottom"]:before:-bottom-px data-[over-position="bottom"]:before:left-0 data-[over-position="bottom"]:before:right-0',
        "[&[data-controlled-hover]:not([data-readonly]):not([data-expanded])[data-hovered]>[data-editor-block-capsule-body]]:border-info-border-light",
        "[&[data-controlled-hover]:not([data-readonly]):not([data-expanded])[data-hovered]>[data-editor-block-capsule-body]]:opacity-100",
        "[&[data-controlled-hover]:not([data-readonly])[data-hovered]_[data-editor-block-capsule-toolbar-button]]:border-grey-border [&[data-controlled-hover]:not([data-readonly])[data-hovered]_[data-editor-block-capsule-toolbar-button]]:bg-white [&[data-controlled-hover]:not([data-readonly])[data-hovered]_[data-editor-block-capsule-toolbar-button]]:text-grey-on",
        "[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button]:focus]:border-grey-border [&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button]:focus]:bg-white [&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button]:focus]:text-grey-on",
        '[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:border-grey-border [&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:bg-white [&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:text-grey-on',
        "[&[data-controlled-hover]:not([data-readonly])[data-hovered]_[data-editor-block-capsule-toolbar-button]:hover]:text-primary-on",
        "[&[data-controlled-hover]:not([data-readonly])[data-hovered]_[data-editor-block-capsule-toolbar-button]:focus]:text-primary-on",
        "[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button]:focus:hover]:text-primary-on",
        "[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button]:focus:focus]:text-primary-on",
        '[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]:hover]:text-primary-on',
        '[&[data-controlled-hover]_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]:focus]:text-primary-on',
        "[&:not([data-controlled-hover]):not([data-readonly]):not([data-expanded]):hover:not(:focus-within)_[data-editor-block-capsule-body]]:border-grey-border-light",
        "[&:not([data-controlled-hover]):not([data-readonly]):not([data-expanded]):hover_[data-editor-block-capsule-sidebar]]:opacity-100",
        "[&:not([data-controlled-hover]):not([data-readonly]):hover_[data-editor-block-capsule-toolbar-button]:hover]:text-primary-on",
        "[&:not([data-controlled-hover]):not([data-readonly]):hover_[data-editor-block-capsule-toolbar-button]:focus]:text-primary-on",
        "[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button]:focus:hover]:text-primary-on",
        "[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button]:focus:focus]:text-primary-on",
        '[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]:hover]:text-primary-on',
        '[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]:focus]:text-primary-on',
        "[&:not([data-controlled-hover]):not([data-readonly]):hover_[data-editor-block-capsule-toolbar-button]]:border-grey-border [&:not([data-controlled-hover]):not([data-readonly]):hover_[data-editor-block-capsule-toolbar-button]]:bg-white [&:not([data-controlled-hover]):not([data-readonly]):hover_[data-editor-block-capsule-toolbar-button]]:text-grey-on",
        "[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button]:focus]:border-grey-border [&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button]:focus]:bg-white [&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button]:focus]:text-grey-on",
        '[&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:border-grey-border [&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:bg-white [&:not([data-controlled-hover])_[data-editor-block-capsule-toolbar-button][aria-expanded="true"]]:text-grey-on',
        props.className,
      )}
    >
      {children}
    </div>
  );
});
