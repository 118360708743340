import { Position } from "css-box-model";
import memoizeOne from "memoize-one";
import { useRef } from "react";
import { useCallback, useMemo } from "use-memo-one";

import { warning } from "../../dev-warning";
import { invariant } from "../../invariant";
import { origin } from "../../services/position";
import { rafSchd } from "../../services/rafSchd";
import type {
  DroppableCallbacks,
  DroppableEntry,
} from "../../services/registry/registry-types";
import type {
  Direction,
  DroppableDescriptor,
  DroppableId,
  Id,
  ScrollOptions,
  TypeId,
} from "../../types";
import AppContext from "../context/app-context";
import * as dataAttr from "../data-attributes";
import useLayoutEffect from "../use-isomorphic-layout-effect";
import usePreviousRef from "../use-previous-ref";
import useRequiredContext from "../use-required-context";
import { useUniqueId } from "../use-unique-id";
import getDimension from "./get-dimension";
import getEnv, { Env } from "./get-env";
import getListenerOptions from "./get-listener-options";
import getScroll from "./get-scroll";

type Props = {
  droppableId: DroppableId;
  type: TypeId;
  direction: Direction;
  isDropDisabled: boolean;
  getDroppableRef: () => HTMLElement | null;
};

type WhileDragging = {
  ref: HTMLElement;
  descriptor: DroppableDescriptor;
  env: Env;
  scrollOptions: ScrollOptions;
};

const getClosestScrollableFromDrag = (
  dragging?: WhileDragging | null,
): HTMLElement | null => (dragging && dragging.env.closestScrollable) || null;

export default function useDroppablePublisher(args: Props) {
  const whileDraggingRef = useRef<WhileDragging | null>(null);
  const appContext = useRequiredContext(AppContext);
  const uniqueId: Id = useUniqueId("droppable");
  const { registry, marshal } = appContext;
  const previousRef = usePreviousRef(args);

  const descriptor = useMemo<DroppableDescriptor>(
    () => ({
      id: args.droppableId,
      type: args.type,
    }),
    [args.droppableId, args.type],
  );
  const publishedDescriptorRef = useRef<DroppableDescriptor>(descriptor);

  const memoizedUpdateScroll = useMemo(
    () =>
      memoizeOne((x: number, y: number) => {
        if (!whileDraggingRef.current) {
          throw invariant("Can only update scroll when dragging");
        }
        const scroll: Position = { x, y };
        marshal.updateDroppableScroll(descriptor.id, scroll);
      }),
    [descriptor.id, marshal],
  );

  const getClosestScroll = useCallback((): Position => {
    const dragging = whileDraggingRef.current;
    if (!dragging || !dragging.env.closestScrollable) {
      return origin;
    }

    return getScroll(dragging.env.closestScrollable);
  }, []);

  const updateScroll = useCallback(() => {
    // reading scroll value when called so value will be the latest
    const scroll = getClosestScroll();
    memoizedUpdateScroll(scroll.x, scroll.y);
  }, [getClosestScroll, memoizedUpdateScroll]);

  const scheduleScrollUpdate = useMemo(
    () => rafSchd(updateScroll),
    [updateScroll],
  );

  const onClosestScroll = useCallback(() => {
    const dragging = whileDraggingRef.current;
    const closest = getClosestScrollableFromDrag(dragging);

    if (!dragging || !closest) {
      throw invariant("Could not find scroll options while scrolling");
    }
    const options = dragging!.scrollOptions;
    if (options.shouldPublishImmediately) {
      updateScroll();
      return;
    }
    scheduleScrollUpdate();
  }, [scheduleScrollUpdate, updateScroll]);

  const getDimensionAndWatchScroll = useCallback(
    (windowScroll: Position, options: ScrollOptions) => {
      if (whileDraggingRef.current) {
        throw invariant("Cannot collect a droppable while a drag is occurring");
      }
      const previous = previousRef.current;
      const ref = previous.getDroppableRef();
      if (!ref) throw invariant("Cannot collect without a droppable ref");
      const env = getEnv(ref);

      const dragging: WhileDragging = {
        ref,
        descriptor,
        env,
        scrollOptions: options,
      };
      // side effect
      whileDraggingRef.current = dragging;

      const dimension = getDimension({
        ref,
        descriptor,
        env,
        windowScroll,
        direction: previous.direction,
        isDropDisabled: previous.isDropDisabled,
      });

      const scrollable = env.closestScrollable;
      if (scrollable) {
        scrollable.setAttribute(
          dataAttr.scrollContainer.contextId,
          appContext.contextId,
        );

        // bind scroll listener
        scrollable.addEventListener(
          "scroll",
          onClosestScroll,
          getListenerOptions(dragging.scrollOptions),
        );
      }

      return dimension;
    },
    [appContext.contextId, descriptor, onClosestScroll, previousRef],
  );

  const getScrollWhileDragging = useCallback((): Position => {
    const dragging = whileDraggingRef.current;
    const closest = getClosestScrollableFromDrag(dragging);
    if (!dragging || !closest) {
      throw invariant(
        "Can only recollect Droppable client for Droppables that have a scroll container",
      );
    }

    return getScroll(closest!);
  }, []);

  const dragStopped = useCallback(() => {
    const dragging = whileDraggingRef.current;
    if (!dragging) throw invariant("Cannot stop drag when no active drag");
    const closest = getClosestScrollableFromDrag(dragging);

    // goodbye old friend
    whileDraggingRef.current = null;

    if (!closest) return;

    // unwatch scroll
    scheduleScrollUpdate.cancel();
    closest.removeAttribute(dataAttr.scrollContainer.contextId);
    closest.removeEventListener(
      "scroll",
      onClosestScroll,
      getListenerOptions(dragging!.scrollOptions),
    );
  }, [onClosestScroll, scheduleScrollUpdate]);

  const scroll = useCallback((change: Position) => {
    // arrange
    const dragging = whileDraggingRef.current;
    if (!dragging) throw invariant("Cannot scroll when there is no drag");
    const closest = getClosestScrollableFromDrag(dragging);
    if (!closest) {
      throw invariant("Cannot scroll a droppable with no closest scrollable");
    }

    // act
    closest!.scrollTop += change.y;
    closest!.scrollLeft += change.x;
  }, []);

  const callbacks = useMemo((): DroppableCallbacks => {
    return {
      getDimensionAndWatchScroll,
      getScrollWhileDragging,
      dragStopped,
      scroll,
    };
  }, [dragStopped, getDimensionAndWatchScroll, getScrollWhileDragging, scroll]);

  const entry = useMemo(
    (): DroppableEntry => ({
      uniqueId,
      descriptor,
      callbacks,
    }),
    [callbacks, descriptor, uniqueId],
  );

  // Register with the marshal and let it know of:
  // - any descriptor changes
  // - when it unmounts
  useLayoutEffect(() => {
    publishedDescriptorRef.current = entry.descriptor;
    registry.droppable.register(entry);

    return () => {
      if (whileDraggingRef.current) {
        warning(
          "Unsupported: changing the droppableId or type of a Droppable during a drag",
        );
        dragStopped();
      }

      registry.droppable.unregister(entry);
    };
  }, [callbacks, descriptor, dragStopped, entry, marshal, registry.droppable]);

  // update is enabled with the marshal
  // only need to update when there is a drag
  useLayoutEffect(() => {
    if (!whileDraggingRef.current) {
      return;
    }
    marshal.updateDroppableIsEnabled(
      publishedDescriptorRef.current.id,
      !args.isDropDisabled,
    );
  }, [args.isDropDisabled, marshal]);
}
