import { Button } from "swash/Button";
import { DialogDisclosure, DialogStore, useDialogStore } from "swash/Dialog";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";

import { ConfirmDialog, ConfirmDialogBody } from "@/components/ConfirmDialog";
import { FORM_ERROR, extractGraphQlErrors } from "@/components/forms/Form";

import { useUsedInContext } from "./Context";

export function DeleteButton({
  deleteNode,
  confirmTitle,
  confirmMessage,
  hasConfirmationText,
}: {
  deleteNode: () => Promise<void>;
  confirmTitle: string;
  confirmMessage: React.ReactNode;
  hasConfirmationText?: boolean;
}) {
  const toaster = useToaster();
  const usedInContext = useUsedInContext();

  const handleSubmit = async () => {
    try {
      await deleteNode();
    } catch (error) {
      const graphQLErrors = extractGraphQlErrors(error);
      const errorMessage = graphQLErrors[FORM_ERROR];
      toaster.danger(errorMessage);
    }
  };

  const dialog = useDialogStore();

  if (usedInContext && usedInContext.isUsed) {
    return (
      <Tooltip tooltip="Cette ressource est encore utilisée, afin de pouvoir la supprimer vous devez d’abord la retirer de toutes ses utilisations">
        <Button type="button" variant="danger" disabled>
          Supprimer
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <DialogDisclosure
        store={dialog}
        render={
          <Button
            type="button"
            variant="danger"
            disabled={usedInContext && usedInContext.loading}
          >
            Supprimer
          </Button>
        }
      />
      <DeleteConfirmDialog
        store={dialog}
        confirmTitle={confirmTitle}
        confirmMessage={confirmMessage}
        hasConfirmationText={hasConfirmationText}
        onConfirm={handleSubmit}
      />
    </>
  );
}

function DeleteConfirmDialog({
  store,
  confirmTitle,
  confirmMessage,
  hasConfirmationText,
  onConfirm,
}: {
  store: DialogStore;
  confirmTitle: string;
  confirmMessage: React.ReactNode;
  hasConfirmationText?: boolean;
  onConfirm: () => Promise<void>;
}) {
  return (
    <ConfirmDialog
      store={store}
      onConfirm={async () => {
        await onConfirm();
        store.hide();
        return true;
      }}
      title={confirmTitle}
      confirmationText={hasConfirmationText ? "SUPPRIMER" : undefined}
      confirmButtonLabel="Supprimer"
    >
      <ConfirmDialogBody>
        <div className="mb-4">
          Une fois supprimée, vous ne pourrez plus jamais utiliser cette
          ressource.
        </div>
        {confirmMessage}
      </ConfirmDialogBody>
    </ConfirmDialog>
  );
}
