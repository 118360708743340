import * as Ariakit from "@ariakit/react";
import { Editor, useEditorState } from "@tiptap/react";
import { forwardRef } from "react";

import { cn } from "../../utils/classNames";
import { useEditorContext } from "../index";

export const Toolbar = forwardRef<HTMLDivElement, EditorToolbarProps>(
  ({ editor, ...props }, ref) => {
    const context = useEditorContext();
    editor = editor || context.editor;

    const editable = useEditorState({
      editor,
      selector: ({ editor }) => editor?.isEditable ?? false,
    });

    if (!editable) return null;

    return (
      <Ariakit.Toolbar
        ref={ref}
        {...props}
        className={cn("toolbar", props.className)}
      />
    );
  },
);

export interface EditorToolbarProps extends Ariakit.ToolbarProps {
  editor?: Editor | null;
}

export const ToolbarItem = forwardRef<HTMLButtonElement, ToolbarItemProps>(
  ({ active, ...props }, ref) => {
    return (
      <Ariakit.ToolbarItem
        ref={ref}
        data-active={active ? "" : undefined}
        {...props}
        className={cn(
          "toolbar-item data-[active]:bg-dusk-on hover:ring-dusk-border-hover",
          props.className,
        )}
      />
    );
  },
);

export interface ToolbarItemProps extends Ariakit.ToolbarItemProps {
  active?: boolean;
}

export const ToolbarSeparator = forwardRef<
  HTMLHRElement,
  ToolbarSeparatorProps
>((props: ToolbarSeparatorProps, ref) => {
  return (
    <Ariakit.ToolbarSeparator
      ref={ref}
      {...props}
      className={cn("separator", props.className)}
    />
  );
});

export interface ToolbarSeparatorProps extends Ariakit.ToolbarSeparatorProps {}
