import { useField } from "react-final-form";
import { IoCheckmarkCircle, IoCloseCircle } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

const items = [{ value: "true" }, { value: "false" }];

function renderIcon(value) {
  switch (value) {
    case "true":
      return (
        <div className="mr-2 flex">
          <IoCheckmarkCircle />
        </div>
      );
    case "false":
      return (
        <div className="mr-2 flex">
          <IoCloseCircle />
        </div>
      );
    default:
      return null;
  }
}

function getWording(value) {
  switch (value) {
    case "true":
      return "Inclus ce qui répond à la condition";
    case "false":
      return "Exclus ce qui répond à la condition";
    default:
      return null;
  }
}

function ValueLabel({ value, label, showTooltip, trueLabel, falseLabel }) {
  if (!value) return label;
  return (
    <div className="flex items-center">
      <Tooltip tooltip={showTooltip ? getWording(value) : null}>
        <span>{renderIcon(value)}</span>
      </Tooltip>
      {value === "true" ? trueLabel : falseLabel}
    </div>
  );
}

export function BooleanFiltersField({
  name,
  label,
  scale,
  trueLabel,
  falseLabel,
  ...others
}) {
  const {
    input: { onChange, value },
  } = useField(name, {
    format: (v) => items.find((i) => i.value === String(v)) || null,
    parse: (v) => {
      switch (v?.value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return null;
      }
    },
    ...others,
  });

  const state = useEnumSelectState({
    value: value || null,
    onChange,
    items,
    labelSelector: ({ value }) => (
      <ValueLabel
        showTooltip
        value={value}
        label={label}
        trueLabel={trueLabel}
        falseLabel={falseLabel}
      />
    ),
    valueSelector: (filter) => filter.value,
  });

  return (
    <div>
      <EnumSelect
        state={state}
        scale={scale}
        placeholder={label}
        aria-label={label}
      />
    </div>
  );
}
