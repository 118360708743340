import createEditorControl from "@/components/editor/controls/createEditorControl";
import { UndoLight } from "@/components/icons";

export const UndoControl = createEditorControl({
  name: "undo",
  exclude: [],
  icon: <UndoLight />,
  command: ({ editor }) => editor.chain().focus().undo().run(),
  canExecute: ({ editor }) => editor.can().undo(),
});
