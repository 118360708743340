import { gql } from "@apollo/client";
import { memo, useCallback } from "react";
import { useToaster } from "swash/Toast";
import { cn } from "swash/utils/classNames";

import { useDetachedInputState } from "@/components/DetachedInputState";
import { SimpleTextEditorSwitcher } from "@/components/editor/SimpleTextEditor";
import { muteEditorEvents } from "@/components/teleporters/EditorBlockCapsule";
import { useSafeMutation } from "@/containers/Apollo";
import { useReadOnly } from "@/containers/ReadOnly";
import { useTextPreset } from "@/containers/editor/presets/preset-text";

const Mutation = gql`
  mutation ImageNodeExpandedCaptionEditor_updateArticleMedia(
    $id: Int!
    $metadata: UpdateArticleMediaMetadataInput
  ) {
    updateArticleMedia(input: { id: $id, metadata: $metadata }) {
      id
      metadata
    }
  }
`;

const ImageNodeExpandedCaptionContainer = (props) => (
  <div
    className={cn(
      "data-[change=deleted]:bg-danger-bg-light data-[change=deleted]:text-danger-on data-[change=deleted]:line-through",
      "data-[change=deleted]:bg-success-bg-light data-[change=added]:text-success-on",
    )}
    {...props}
  />
);

const Caption = ({ caption }) => (
  <div className="m-h-9 flex items-center border border-transparent px-2 py-1">
    {caption || "Légende"}
  </div>
);
const Credit = ({ credit }) => {
  if (!credit) return null;
  return <strong className="block px-2 py-1">{credit}</strong>;
};
const CaptionEditor = memo(({ caption, onSubmit }) => {
  const plugins = useTextPreset();
  const detachedProps = useDetachedInputState({
    value: caption,
    onChange: onSubmit,
  });
  return (
    <div className="[&:has([aria-expanded='true'])]:!bg-transparent hover:[&:not(:focus-within)]:bg-grey-bg-hover-transparent">
      {detachedProps.blocked && "bloqué"}
      <SimpleTextEditorSwitcher
        name="caption"
        label="Légende"
        plugins={plugins}
        rich
        appearance="inline"
        showTools
        placeholder="Légende"
        multiline
        rows={1}
        maxRows={5}
        {...detachedProps}
      />
    </div>
  );
});

export const ImageNodeExpandedCaptionEditor = memo(
  ({ articleMediaId, caption, credit }) => {
    const [updateArticleMediaMetadata] = useSafeMutation(Mutation, {
      variables: { id: articleMediaId },
      skip: !articleMediaId,
    });
    const toaster = useToaster();
    const readOnly = useReadOnly();

    const handleSubmit = useCallback(
      async (value) => {
        if (value !== caption) {
          await updateArticleMediaMetadata({
            variables: {
              metadata: { caption: value },
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateArticleMedia: {
                __typename: "ArticleMedia",
                id: articleMediaId,
                metadata: { caption: value },
              },
            },
          }).catch(() => {
            toaster.danger(
              "La mise à jour de la légende de l’image dans l’article a échoué",
            );
          });
        }
      },
      [articleMediaId, toaster, updateArticleMediaMetadata, caption],
    );

    const canEdit = Boolean(articleMediaId) && !readOnly;

    return (
      <ImageNodeExpandedCaptionContainer
        className="relative z-[1] rounded-sm text-base leading-5 text-grey-on [&>hr]:border-grey-border-light"
        {...(canEdit && { ...muteEditorEvents() })}
      >
        {canEdit ? (
          <CaptionEditor caption={caption} onSubmit={handleSubmit} />
        ) : (
          <Caption caption={caption} />
        )}

        <Credit credit={credit} />
      </ImageNodeExpandedCaptionContainer>
    );
  },
);
