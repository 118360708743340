import { type DocumentNode, gql } from "@apollo/client";
import { Children, cloneElement, forwardRef, useEffect, useState } from "react";
import { Portal } from "swash/Portal";
import {
  ReferenceType,
  autoPlacement,
  autoUpdate,
  offset,
  safePolygon,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "swash/floating";
import { BubbleExclamation } from "swash/icons";

import type { ArticleHoverCard_articleFragment } from "@/gql-types";

import { ExposureIndicator } from "../ExposureIndicator";
import { ArticleConfidentialLabel } from "./ArticleConfidentialLabel";
import { ArticleDescriptionNarrowSection } from "./ArticleDescriptionNarrowSection";
import { ArticleDescriptionSigns } from "./ArticleDescriptionSigns";
import { ArticleEditorialFormatLabel } from "./ArticleEditorialFormatLabel";
import { ArticleEditorialLevelIcon } from "./ArticleEditorialLevelIcon";
import { ArticleEditorialTypeLabel } from "./ArticleEditorialTypeLabel";
import { ArticleEvergreenAlert } from "./ArticleEvergreenAlert";
import { ArticleFeaturedMedia } from "./ArticleFeaturedMedia";
import { ArticleIdLabel } from "./ArticleIdLabel";
import { ArticleLayoutPricingIndicator } from "./ArticleLayoutPricingIndicator";
import { ArticlePredicates } from "./ArticlePredicates";
import { ArticlePublishDateTimeStatus } from "./ArticlePublishDateTimeStatus";
import { ArticleReferenceLabel } from "./ArticleReferenceLabel";
import { ArticleStatesTooltip } from "./ArticleStatesTooltip";
import { ArticleTitle } from "./ArticleTitle";
import { ArticleStateIcon } from "./state/ArticleStateIcon";
import { getArticleStatusColor } from "./util/status";

type ArticleHoverCardInnerProps = {
  article: ArticleHoverCard_articleFragment;
};
export type ArticleHoverCardFragments = {
  article: DocumentNode;
};

export type ArticleHoverCardProps = ArticleHoverCardInnerProps & {
  children: React.ReactElement;
  positionReference?: ReferenceType;
};

export const ArticleHoverCardInner = forwardRef<
  HTMLDivElement,
  ArticleHoverCardInnerProps
>(({ article }, ref) => {
  const state = article.state;
  return (
    <div
      ref={ref}
      className="flex flex-row gap-4 rounded border bg-white p-4 shadow"
      style={{
        borderLeftWidth: 3,
        borderLeftColor: getArticleStatusColor(article),
      }}
    >
      <div className="flex flex-col items-center justify-start gap-2 pt-1">
        <ArticleLayoutPricingIndicator article={article} />
        {state && (
          <ArticleStatesTooltip article={article}>
            <ArticleStateIcon state={state} />
          </ArticleStatesTooltip>
        )}
        <ArticleEditorialLevelIcon article={article} />
      </div>
      <div className="flex flex-grow flex-col gap-4">
        <div className="flex flex-row items-start gap-2">
          <h3 className="flex-grow text-base leading-5 [&>*:first-child]:before:hidden [&>*]:before:px-1.5 [&>*]:before:text-grey-border-light [&>*]:before:content-['|']">
            <span>
              <ArticleTitle article={article} />
            </span>
            {article.narrowSection && (
              <ArticleDescriptionNarrowSection article={article} />
            )}
          </h3>
          <ArticleFeaturedMedia
            article={article}
            className="w-[114px] shrink-0 overflow-hidden rounded-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row flex-wrap items-center justify-start gap-x-2 gap-y-0.5 text-xs text-grey-on">
            <ArticlePublishDateTimeStatus article={article} />
            <ArticleConfidentialLabel article={article} />
            <ArticleReferenceLabel article={article} />
            <ArticleEditorialFormatLabel article={article} />
            <ArticleEditorialTypeLabel article={article} />
            <ArticleIdLabel article={article} />
            <ArticleDescriptionSigns article={article} disabled />
            <ArticleEvergreenAlert article={article} />
            <ArticlePredicates article={article} />
          </div>
          {article.signature && (
            <div className="text-sm text-grey-on">Par {article.signature}</div>
          )}
          {(article.hasNotes || article.articleExposures.nodes.length) && (
            <div className="flex flex-row justify-start gap-x-2 gap-y-1">
              {article.hasNotes && <BubbleExclamation />}
              {article.articleExposures.nodes.map((articleExposure) => (
                <ExposureIndicator
                  key={articleExposure.gid}
                  article={article}
                  // FIXME: Typing is not very clean in ExposureIndicator
                  articleExposure={articleExposure as any}
                  exposure={articleExposure.exposure as any}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export const ArticleHoverCard: React.FC<ArticleHoverCardProps> & {
  fragments: ArticleHoverCardFragments;
} = ({ children, positionReference, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ["left", "right", "top-start", "bottom-start"],
      }),
    ],
    placement: "left",
  });

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon(),
    delay: { open: 500, close: 0 },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const child = Children.only(children);

  useEffect(() => {
    if (positionReference) refs.setPositionReference(positionReference);
  }, [positionReference, refs]);

  return (
    <>
      {cloneElement(child, { ...getReferenceProps(), ref: refs.setReference })}
      {isOpen && (
        <Portal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 420,
            }}
            className="z-tooltip"
            {...getFloatingProps()}
          >
            <ArticleHoverCardInner {...props} />
          </div>
        </Portal>
      )}
    </>
  );
};

ArticleHoverCard.fragments = {
  article: gql`
    fragment ArticleHoverCard_article on Article {
      ...getArticleStatusColor
      ...ArticleLayoutPricingIndicator_article
      ...ArticleStatesTooltip_article
      ...ArticleEditorialLevelIcon_article
      ...ArticleTitle_article
      ...ArticleDescriptionNarrowSection_article
      ...ArticleFeaturedMedia_article
      ...ArticlePublishDateTimeStatus_article
      ...ArticleConfidentialLabel_article
      ...ArticleReferenceLabel_article
      ...ArticleEditorialFormatLabel_article
      ...ArticleEditorialTypeLabel_article
      ...ArticleIdLabel_article
      ...ArticleDescriptionSigns_article
      ...ArticleEvergreenAlert_article
      ...ArticlePredicates_article
      signature
      hasNotes
      ...ExposureIndicator_article
      articleExposures {
        nodes {
          ...ExposureIndicator_articleExposure
          exposure {
            ...ExposureIndicator_exposure
          }
          publication {
            ...ExposureIndicator_publication
          }
        }
      }
    }

    ${getArticleStatusColor.fragment}
    ${ArticleLayoutPricingIndicator.fragments.article}
    ${ArticleStatesTooltip.fragments.article}
    ${ArticleEditorialLevelIcon.fragments.article}
    ${ArticleTitle.fragments.article}
    ${ArticleDescriptionNarrowSection.fragments.article}
    ${ArticleFeaturedMedia.fragments.article}
    ${ArticlePublishDateTimeStatus.fragments.article}
    ${ArticleConfidentialLabel.fragments.article}
    ${ArticleReferenceLabel.fragments.article}
    ${ArticleEditorialFormatLabel.fragments.article}
    ${ArticleEditorialTypeLabel.fragments.article}
    ${ArticleIdLabel.fragments.article}
    ${ArticleDescriptionSigns.fragments.article}
    ${ArticleEvergreenAlert.fragments.article}
    ${ArticlePredicates.fragments.article}
    ${ExposureIndicator.fragments.articleExposure}
    ${ExposureIndicator.fragments.article}
    ${ExposureIndicator.fragments.publication}
    ${ExposureIndicator.fragments.exposure}
  `,
};
