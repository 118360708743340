import createEditorControl from "@/components/editor/controls/createEditorControl";
import { RedoLight } from "@/components/icons";

export const RedoControl = createEditorControl({
  name: "redo",
  exclude: [],
  icon: <RedoLight />,
  command: ({ editor }) => editor.chain().focus().redo().run(),
  canExecute: ({ editor }) => editor.can().redo(),
});
