import { Node } from "@tiptap/core";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Heading } from "@tiptap/extension-heading";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { Underline } from "@tiptap/extension-underline";

import { InvisibleCharactersExtension } from "../extensions/invisible-characters";
import { RemoveStyleExtension } from "../extensions/remove-style";
import { SpecialCharactersExtension } from "../extensions/special-characters";
import { ToggleCaseExtension } from "../extensions/toggle-case";
import { TypographicRulesExtension } from "../extensions/typographic-rules";
import { Chapo } from "../nodes/chapo";
import { EmojiNode } from "../nodes/emoji";
import { Title } from "../nodes/title";
import { Core, createKit } from "./base";

const ArticleDocument = Node.create<never>({
  name: "doc",
  topNode: true,
  content: "title chapo block+",
});

export const ArticleKit = createKit("articleKit", () => ({
  ...Core,

  // Nodes
  blockquote: Blockquote,
  bulletList: BulletList,
  chapo: Chapo,
  document: ArticleDocument,
  emoji: EmojiNode,
  heading: Heading.configure({ levels: [2, 3] }),
  horizontalRule: HorizontalRule,
  listItem: ListItem, // TODO: Allows `sinkListItem`, but it's not configurable through options. Should we consider extending it? This may cause breaking changes.
  orderedList: OrderedList,
  title: Title,

  // Marks
  bold: Bold,
  italic: Italic,
  strike: Strike,
  subscript: Subscript,
  superscript: Superscript,
  underline: Underline,

  // Functionality
  invisibleCharacters: InvisibleCharactersExtension,
  removeStyle: RemoveStyleExtension,
  specialCharacters: SpecialCharactersExtension,
  toggleCase: ToggleCaseExtension,
  typographicRules: TypographicRulesExtension,
}));
