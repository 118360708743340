import memoizeOne from "memoize-one";
import { MutableRefObject, useRef } from "react";
import { useCallback, useMemo } from "use-memo-one";

import { invariant } from "../../invariant";
import type { ContextId, DropReason } from "../../types";
import { prefix } from "../data-attributes";
import useLayoutEffect from "../use-isomorphic-layout-effect";
import getStyles, { Styles } from "./get-styles";

export type StyleMarshal = {
  dragging: () => void;
  dropping: (reason: DropReason) => void;
  resting: () => void;
};

const getHead = (): HTMLHeadElement => {
  const head = document.querySelector("head");
  if (!head) throw invariant("Cannot find the head to append a style to");
  return head!;
};

const createStyleEl = (): HTMLStyleElement => {
  const el = document.createElement("style");
  el.type = "text/css";
  return el;
};

export default function useStyleMarshal(contextId: ContextId) {
  const styles: Styles = useMemo(() => getStyles(contextId), [contextId]);
  const alwaysRef = useRef<HTMLStyleElement | null>(null);
  const dynamicRef = useRef<HTMLStyleElement | null>(null);

  const setDynamicStyle = useCallback(
    // Using memoizeOne to prevent frequent updates to textContext
    memoizeOne((proposed: string) => {
      const el: HTMLStyleElement | null = dynamicRef.current;
      if (!el) {
        throw invariant("Cannot set dynamic style element if it is not set");
      }
      el!.textContent = proposed;
    }),
    [],
  );

  const setAlwaysStyle = useCallback((proposed: string) => {
    const el: HTMLStyleElement | null = alwaysRef.current;
    if (!el) {
      throw invariant("Cannot set dynamic style element if it is not set");
    }
    el!.textContent = proposed;
  }, []);

  // using layout effect as programatic dragging might start straight away (such as for cypress)
  useLayoutEffect(() => {
    if (alwaysRef.current || dynamicRef.current) {
      throw invariant("style elements already mounted");
    }

    const always: HTMLStyleElement = createStyleEl();
    const dynamic: HTMLStyleElement = createStyleEl();

    // store their refs
    alwaysRef.current = always;
    dynamicRef.current = dynamic;

    // for easy identification
    always.setAttribute(`${prefix}-always`, contextId);
    dynamic.setAttribute(`${prefix}-dynamic`, contextId);

    // add style tags to head
    getHead().appendChild(always);
    getHead().appendChild(dynamic);

    // set initial style
    setAlwaysStyle(styles.always);
    setDynamicStyle(styles.resting);

    return () => {
      const remove = (ref: MutableRefObject<HTMLStyleElement | null>) => {
        const current: HTMLStyleElement | null = ref.current;
        if (!current) invariant("Cannot unmount ref as it is not set");
        getHead().removeChild(current!);
        ref.current = null;
      };

      remove(alwaysRef);
      remove(dynamicRef);
    };
  }, [
    setAlwaysStyle,
    setDynamicStyle,
    styles.always,
    styles.resting,
    contextId,
  ]);

  const dragging = useCallback(
    () => setDynamicStyle(styles.dragging),
    [setDynamicStyle, styles.dragging],
  );
  const dropping = useCallback(
    (reason: DropReason) => {
      if (reason === "DROP") {
        setDynamicStyle(styles.dropAnimating);
        return;
      }
      setDynamicStyle(styles.userCancel);
    },
    [setDynamicStyle, styles.dropAnimating, styles.userCancel],
  );
  const resting = useCallback(() => {
    // Can be called defensively
    if (!dynamicRef.current) {
      return;
    }
    setDynamicStyle(styles.resting);
  }, [setDynamicStyle, styles.resting]);

  const marshal = useMemo(
    (): StyleMarshal => ({
      dragging,
      dropping,
      resting,
    }),
    [dragging, dropping, resting],
  );

  return marshal;
}
