import { useMemo } from "react";

import { CoreArticle_articleFragment } from "@/gql-types";
import { createSafeContext } from "@/services/hooks/useSafeContext";

import { useRemoteConfig } from "../RemoteConfig";

type BaseEditorConfig = CoreArticle_articleFragment["editorConfig"];
type IEditorConfig = {
  services: string[];
  defaultLanguage: string;
  plugins: { name: string }[];
};

const EditorConfigContext = createSafeContext<IEditorConfig>();

export function EditorConfigProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config?: BaseEditorConfig | null;
}) {
  const { editorConfig, services, defaultLanguage } = useRemoteConfig();
  const value = useMemo(() => {
    const baseConfig = config ?? editorConfig;
    return { ...baseConfig, services, defaultLanguage };
  }, [config, services, defaultLanguage, editorConfig]);

  return (
    <EditorConfigContext.Provider value={value}>
      {children}
    </EditorConfigContext.Provider>
  );
}

export const useEditorConfig = EditorConfigContext.makeSafeHook(
  "useEditorConfig",
  "EditorConfigProvider",
);
