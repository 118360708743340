import { parsePhoneNumber } from "awesome-phonenumber";
import { validate as validateEmail } from "email-validator";
import * as moment from "moment";
import { checkIsValidHexColor } from "swash/controls/ColorPicker";
import { checkIsValidFullTimeValue } from "swash/controls/TimePickerInput";

import { ERRORS } from "@/config/messages";

export { mustBeURL } from "./validators/mustBeUrl";

const format = (str, ...args) => {
  return str.replace(/{(\d+)}/g, (match, number) => {
    return args[number] !== undefined ? args[number] : match;
  });
};

export const mustBeFilled = (value) => {
  const errorMessage = ERRORS.forms.validators.mustBeFilled;
  if (Array.isArray(value) && value.length === 0) {
    return errorMessage;
  }
  if (typeof value === "string") {
    // eslint-disable-next-line no-param-reassign
    value = value.trim();
  }
  if (value === "" || value === null || value === undefined) {
    return errorMessage;
  }
};

export const mustBeEmail = (value) => {
  if (value && !validateEmail(value)) {
    return ERRORS.forms.validators.mustBeEmail;
  }
};

export const mustBePhoneNumber = (regionCode) => (value) => {
  if (!value) return;
  const phoneParsed = parsePhoneNumber(value, { regionCode });
  if (!phoneParsed.valid) {
    return ERRORS.forms.validators.mustBePhoneNumber;
  }
};

export const mustBeHex = (value) => {
  if (value && !checkIsValidHexColor(value)) {
    return ERRORS.forms.validators.mustBeHex;
  }
};

export const mustHaveMinLength = (min) => (value) => {
  if (value && value.length < min) {
    return format(ERRORS.forms.validators.mustHaveMinLength, min);
  }
};

export const mustHaveMaxLength = (max) => (value) => {
  if (value && value.length > max) {
    return format(ERRORS.forms.validators.mustHaveMaxLength, max);
  }
};

export const mustBeMoreThan = (min) => (value) => {
  if (value && value < min) {
    return format(ERRORS.forms.validators.mustBeMoreThan, min);
  }
};

export const mustBeLessThan = (max) => (value) => {
  if (value && value > max) {
    return format(ERRORS.forms.validators.mustBeLessThan, max);
  }
};

export const mustBeFullTime = (value) => {
  if (value && !checkIsValidFullTimeValue(value)) {
    return ERRORS.forms.validators.mustBeFullTime;
  }
};

export const mustBeInFuture = (value) => {
  if (value && moment(value).isBefore(moment())) {
    return ERRORS.forms.validators.mustBeInFuture;
  }
};

export const composeValidators =
  (...validators) =>
  (...args) =>
    validators
      .filter(Boolean)
      .reduce((error, validator) => error || validator(...args), undefined);
